@headerHeight: 64px;

.header {
  padding-left: 200px;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  height: 46px;
  line-height: 46px;
  background-color: #001529;

  span {
    padding: 6px 30px;
    background: rgba(255, 255, 255, 0.2);
    font-weight: 500;
  }
}

.subjectName {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #fff;
  font-size: 18px;
}

@hack: true; @import "/Users/zhanghedong/study/momo/study-web-manager/src/styles/ant-theme.less";