body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  border: 0;
  margin: 0;
  padding: 0;
}

iframe:not([class="show"]) {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

audio {
  height: 36px
}

ul, ol {
  list-style: none;
}

dot {
  border-bottom: 2px dotted;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: rgba(34, 34, 34, 0.3);
}

:global {
  #root {
    min-width: 1344px;
    background-color: #efefef;
  }
}

@hack: true; @import "/Users/zhanghedong/study/momo/study-web-manager/src/styles/ant-theme.less";