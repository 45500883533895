@headerHeight: 64px;

.logo {
  margin: 16px 0 16px 24px;
  color: #fff;
  font-weight: 700;
  line-height: 32px;
}

//.headerWrap {
//    position: fixed;
//    top: 0;
//    width: 100%;
//}

.layout {
  display: flex;
  height: calc(100% - 46px)
}

.sider {
  min-height: calc(100% - 46px);
  li{
    padding-left: 20px!important;
  }
}

.contentWrap {
  flex: auto;
  min-width: 0;
  height: calc(100% - 20px);
  overflow-y: scroll;
}

@hack: true; @import "/Users/zhanghedong/study/momo/study-web-manager/src/styles/ant-theme.less";